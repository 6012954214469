<template>
  <list-template
      :loading="loading"
      :total="total"
      :current-page="page"
      :table-data="tableData"
      :table-config="tableConfig"
      :search-config="searchConfig"
      @onChangePage="changeCurrentPage"
      @onSearch="onSearch"
      @onHandle="tableHandle"
      @onReset="onReset"
      hasAdd
      @onAdd="onAdd"
      ref="table"
  >
  </list-template>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed:{
    ...mapState(["page"]),
    ...mapGetters(["userInfo"])
  },
  data(){
    return {
      loading: true,
      // 搜索框的选中数据
      search:null,
      // 搜索可用值
      searchVal:null,
      // 数据总量
      total:0,
      searchConfig:[
        {
          prop: 'school_id',
          tag: 'select',
          placeholder: '请选择校区',
          options: [],
          label: 'name',
          value: 'id',
          change: (val, search) => {
            this.search = search
            this.search.grade_id = ''
            let params = {
              school_id: search.school_id
            }
            if (search.subject_id) {
              params.subject_id = search.subject_id
            }
            this.$_axios2.get('api/common/school', {params}).then(res => {
							let data = res.data.data;
							this.searchConfig[1].options = data.action_grade;
							if (data.subject_data.length) {
								let boo = false
								data.subject_data.forEach(item => {
									if (item.subject_id === search.subject_id) boo = true
								})
								if (!boo) {
									this.search.subject_id = ''
								}
							} else {
								this.search.subject_id = ''
							}
						})
          }
        },
        {
          prop: 'grade_id',
          tag: 'select',
          placeholder: '请选择年级',
          options: [],
          label: 'name',
          value: 'id',
        },
        {
          prop:"subject_id",
          placeholder:"请选择科目",
          tag:"select",
          options:[],
          label: 'name',
          value: 'subject_id',
        },
        {
          prop:"month",
          tag:"datePicker",
          placeholder:"请选择年月",
          property:{
            size:"small",
            style:"margin-right:20rem;",
            type:"month",
            valueFormat: "yyyyMM",
          }
        },
      ],
      tableData:[],
      tableConfig:[
      {
          width: "80rem",
          label: '序号',
          type: 'number',
          textAlign: 'left'
        },
        {
          prop:"month",
          label:"年月",
        },
        {
          prop:"subject_info",
          label:"科目",
        },
        {
          prop:"grade_info",
          label:"年级"
        },
        {
          prop:"updated_at",
          label:"更新时间",
        },
        {
          prop:"school_info",
          label:"校区",
        },
        {
          prop:"creator_name",
          label:"创建人",
        },
        {
          prop:""  ,
          label:"操作",
          handle:true,
          showOverflowTooltip: false,
          buttons: (row) => {
            let button  = [];
            row.button.forEach((element, key) => {
              if(element == 'edit'){
                button[key] = { type:"edit", text:"编辑" };
              }
              if(element == 'show'){
                button[key] = { type:"view", text:"查看" };
              }
            });
            return button
          }
        },
      ],
    }
  },
  mounted() {
    this.$store.commit("setPage",1);
    this.commonInterface(); // 获取可筛选项
    this.getData(); // 获取数据
  },
  activated() {
    this.getData(); // 获取数据
  },
  methods:{
		...mapMutations(["setPage"]),
		commonInterface() {
			this.$_axios2.get('api/common/school').then(res => {
				let data = res.data.data
				this.searchConfig[1].options = data.action_grade;
				this.searchConfig[0].options = data.school;
			})
			this.$_axios2.get('api/educational-routine/subject-select').then(res => {
				this.searchConfig[2].options = res.data.data;
			})
		},
		// 获取列表基础数据
		getData() {
			this.loading = true
			this.$_axios2.get("api/educational-routine/list", {params: {...this.searchVal, page: this.page}}).then(res => {
				let data = res.data.data
				if (!(Array.isArray(data))) {
					this.tableData = data.list
					this.total = data.page.total
				} else {
					this.tableData = []
				}
			}).catch().finally(() => {this.loading = false})
		},
		// 新增按钮
		onAdd() {
			this.$router.push('./add')
		},
		changeCurrentPage(e) {
			this.setPage(e)
			this.getData();
		},
		onSearch(val) {
			this.setPage(1);
			this.search = val;
			this.searchVal = val;
			this.getData();
		},
		// 表格后面操作被点击
		tableHandle(row, handle) {
      const { type } = handle
			if (type === 'view') {
				this.$router.push('./details?id=' + row.id)
			}
			if (type === 'edit') {
				this.$router.push('./edit?id=' + row.id)
			}
		},

    onReset(){
      this.search = null;
      this.searchVal = null;
    }
	}
}
</script>

<style scoped>

</style>
